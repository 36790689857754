<template>
  <div class="main">
    <div class="title">
      <div class="title_left">
        <div class="title_left_img"
          :style="{ background: student.student_gender == 1 ? 'url(' + require('../../../../assets/img/5期/boy.png') + ') no-repeat' : 'url(' + require('../../../../assets/img/5期/girl.png') + ') no-repeat' }">
        </div>
        <div class="title_left_str">{{ student.student_name }}</div>
      </div>
      <div class="title_rigth">
        <div class="title_rigth_bg">
          {{sel.evaluation}}
        </div>
        <div class="title_rigth_time" v-if="sel.state === 1">测试完成时间:{{sel.completion_at}}</div>
        <div class="title_rigth_box">
          <div class="title_rigth_box_left">
            <div class="title_rigth_box_left_name">年龄</div>
            <div class="title_rigth_box_left_str">{{ student.age }}</div>
          </div>
          <div class="title_rigth_box_left">
            <div class="title_rigth_box_left_name">校区</div>
            <div class="title_rigth_box_left_str">{{ student.school }}</div>
          </div>
          <div class="title_rigth_box_left">
            <div class="title_rigth_box_left_name">年级</div>
            <div class="title_rigth_box_left_str">{{ student.grade }}</div>
          </div>
          <div class="title_rigth_box_left">
            <div class="title_rigth_box_left_name">行政班</div>
            <div class="title_rigth_box_left_str">{{ student.class }}</div>
          </div>
          <div class="title_rigth_box_left">
            <div class="title_rigth_box_left_name">家庭住址</div>
            <div class="title_rigth_box_left_str">{{ student.home_address }}</div>
          </div>
        </div>
      </div>
      <div class="title_img"></div>
    </div>
    <div class="middle" v-if="sel.state !== 0">
      <div class="middle_top_bg">总体评价</div>
      <div class="middle_box">
        <div class="middle_box_left">
          <div
            style="width: 132rem;height: 132rem;background: #FEEED8;border-radius: 50%;display: flex;justify-content: center;align-items: center;"
            :style="{ background: sel.status === 1 ? '#f8fffa' : sel.status === 2 ? '#fffcf7' : '#fff8f7' ,color: sel.status === 1 ? '#06c270' : sel.status === 2 ? '#fe9e62' : '#f76c67' }">
            {{ sel.score }}
          </div>
        </div>
        <div class="middle_box_rigth" style="display: flex;align-items: center">
          {{ sel.evaluate }}
        </div>
      </div>
    </div>
    <div class="footer" v-if="sel.state !== 0">
      <div class="footer_box" v-for="item in sel_type" :style="{ background: item.state == 1 ? '#f8fffa' : item.state == 2 ? '#fffcf7' : '#fff8f7' }">
        <div class="footer_box_left"
          :style="{ background: item.state == 1 ? '#f0fbf3' : item.state == 2 ? '#feeed8' : '#ffeeeb' }">
          {{ item.name }}
        </div>
        <div class="footer_box_rigth"
          :style="{ background: item.state == 1 ? '#f8fffa' : item.state == 2 ? '#fffcf7' : '#fff8f7' }">{{ item.score}}分,{{ item.evaluate }}
        </div>
      </div>
    </div>
    <div v-else style="background: #fff;width: 99%;margin-top: 24rem;border-radius: 24rem">
      <t-result type="empty" text="测试未完成,还没有数据提交上来哦"></t-result>
    </div>
    <el-button @click="()=>this.$router.back()" style="margin-top: 24rem">返回</el-button>
  </div>
</template>

<script>
import TResult from "@/components/Result";
export default {
	_config:{"route":{"path":"examine","meta":{"title":"查看"}}},
  components: {TResult},
  data () {
    return {
      sel: {},
      sel_type: [],
      student: {}
    }
  },
  created () {
    this.$_axios2.get('api/evaluation/result/' + this.$route.query.id).then(res => {
      this.sel = res.data.data.sel
      this.sel_type = res.data.data.sel_type
      this.student = res.data.data.student
    })
  },
}
</script>

<style scoped lang="scss">
	.main {
		background: #f7f7f7 !important;

		.title {
			width: 99%;
			height: 491rem;
			background: linear-gradient(180deg, #90c3ff 0%, #add5ff 19%, #d5eaff 57%, rgba(180, 223, 250, 0.07) 100%);
			opacity: 1;
			border-radius: 20rem;
			display: flex;
			position: relative;

			.title_img {
				width: 178rem;
				height: 337rem;
				background: url('../../../../assets/img/5期/mostcircle.png') no-repeat;
				background-size: 100%;
				position: absolute;
				right: 0;
			}
		}
	}

	.title_left {
		width: 40%;
		height: 400rem;
		margin: 44rem 0 0 13.4%;

		.title_left_img {
			width: 100%;
			height: 320rem;
      background-position-x: 100rem;
			background-size: 300rem !important;
      margin-left: 128rem;
		}

		.title_left_str {
			width: 133rem;
			height: 35rem;
			background: #3993f8;
			border-radius: 20rem 20rem 20rem 20rem;
			opacity: 1;
			text-align: center;
			line-height: 35rem;
			font-size: 20rem;
			font-weight: 600;
			color: #ffffff;
      margin-left: 128rem;

    }
	}

	.title_rigth {
		width: 100%;
		height: 90%;
    margin-left: 128rem;

		.title_rigth_bg {
			display: flex;
			padding-top: 29rem;
			background: url('../../../../assets/img/5期/semicycle.png') no-repeat;
			background-position-x: 300rem;
			background-size: 55rem;
			height: 68rem;
			font-size: 26rem;

			font-weight: 600;
			color: #333333;
			line-height: 24rem;
			margin-top: 29rem;
		}

		.title_rigth_time {
			font-weight: 400;
			color: #666666;
			line-height: 24rem;
      font-size: 14rem;
		}

		.title_rigth_box {
			width: 504rem;
			height: 310rem;
			background: #fdfdfd;
			opacity: 1;
			border-radius: 20rem;
			margin-top: 12rem;
			padding: 24rem;

			.title_rigth_box_left {
				margin-bottom: 24rem;
				width: 100%;
				display: flex;

				.title_rigth_box_left_name {
					width: 100rem;
					font-size: 18rem;
					font-weight: 400;
					color: #666666;
				}

				.title_rigth_box_left_str {
					width: 322rem;
					height: 27rem;
					font-size: 20rem;
					font-weight: 400;
					color: #333333;
					line-height: 24rem;
				}
			}
		}
	}

	.middle {
		width: 99%;
		height: 301rem;
		background: #ffffff;
		border-radius: 20rem 20rem 20rem 20rem;
		opacity: 1;
		//margin-top: 40rem;

		.middle_top_bg {
			width: 205rem;
			height: 54rem;
			background: url('../../../../assets/img/5期/ztpjbg.png') no-repeat;
			text-align: center;
			line-height: 54rem;
			font-size: 20rem;

			font-weight: 600;
			color: #333333;
		}

		.middle_box {
			height: 247rem;
			width: 100%;
			display: flex;

			.middle_box_left {
				width: 20%;
				height: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 60rem;

				font-weight: 600;
				color: #fe9454;
			}

			.middle_box_rigth {
				width: 80%;
				height: 100%;
				font-size: 18rem;
				font-weight: 400;
				color: #333333;
				padding: 0 24rem;
			}
		}
	}

	.footer {
		width: 99%;
		min-height: 100rem;
		border-radius: 20rem;
		background: #fff;
		margin-top: 12rem;
		padding: 32rem;

		.footer_box {
			width: 100%;
			min-height: 80rem;
			display: flex;
			margin-bottom: 24rem;
      border-radius: 14rem 14rem 14rem 14rem;

			.footer_box_left {
				width: 12%;
				min-height: 100%;
				background: #feeed8;
				border-radius: 14rem 14rem 14rem 14rem;
				display: flex;
				align-items: center;
				font-weight: 600;
				color: #333333;
				padding-left: 24rem;
			}

			.footer_box_rigth {
				width: 80%;
				min-height: 100%;
				background: #fffcf7;
				border-radius: 14rem 14rem 14rem 14rem;
				display: flex;
				align-items: center;
				font-weight: 400;
				color: #333333;
				padding-left: 24rem;
			}
		}
	}
</style>
